exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-players-jsx": () => import("./../../../src/pages/players.jsx" /* webpackChunkName: "component---src-pages-players-jsx" */),
  "component---src-pages-tournaments-australia-2023-index-jsx": () => import("./../../../src/pages/tournaments/australia-2023/index.jsx" /* webpackChunkName: "component---src-pages-tournaments-australia-2023-index-jsx" */),
  "component---src-template-player-jsx": () => import("./../../../src/template/Player.jsx" /* webpackChunkName: "component---src-template-player-jsx" */),
  "component---src-template-team-jsx": () => import("./../../../src/template/Team.jsx" /* webpackChunkName: "component---src-template-team-jsx" */),
  "component---src-template-tournament-jsx": () => import("./../../../src/template/Tournament.jsx" /* webpackChunkName: "component---src-template-tournament-jsx" */)
}

